<template>
    <div>
        <button class="btn" :class="classes" type="button" @click.prevent="openLoginModal">
            <i class="far fa-user"></i>{{ $locale == 'es' ? 'Iniciar sesión' : 'Login' }}
        </button>

        <LoginModal ref="loginModal"/>
    </div>
</template>
<script>
import LoginModal from './LoginModal.vue';
export default {
    props: {
        classes: String
    },
    components: { LoginModal },
    methods: {
        openLoginModal() {
            this.$refs.loginModal.show();
        }
    }
}
</script>