import Vue from 'vue'
import App from '../components/login-btn/App.vue';
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, { inject: false });

const containers = document.querySelectorAll(`div[id^="login-btn-"]`);

containers.forEach((container) => {
    document.addEventListener('DOMContentLoaded', () => {
        const app = new Vue({
            render: h => h(App, { props: { classes: container.id.includes('2') ? 'nav-link text-nowrap text-light' : '' } })
        }).$mount(`#${container.id}`)
    })
});