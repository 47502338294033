
<Modal v-if="showModal" @close="hide()">
  <h5 slot="header" class="modal-title pl-3"></h5>

  <div class="pl-3 pr-3" slot="body">
    <h5 class="f-weight-7 text-dark border-bottom pb-2">{{ $locale == 'es' ? 'Inicia sesión' : 'Log in'}}</h5>
    <p class="description mb-2 text-secondary f-weight-7"> {{ titleText }} </p>

    <div class="row" v-if="isNotRegistred">
        <p class="f-weight-7 text-dark mb-2">{{ $locale == 'es' ? 'Correo electrónico' : 'E-mail'}}</p>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <input
                name="given_name"
                class="input-type-2 mb-2 w-100"
                :data-vv-as="'given_name'"
                v-validate="'required'"
                v-model="user.given_name"
                :placeholder="givenNamePlaceholder"/>
            <label class="has_error" v-show="errors.has('given_name')">
                {{ $locale == 'es' ? 'Este campo es requerido' : 'This field is required'}}
            </label>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <input
                name="last_name"
                class="input-type-2 mb-2 w-100"
                :data-vv-as="'last_name'"
                v-validate="'required'"
                v-model="user.last_name"
                :placeholder="lastNamePlaceholder"/>
            <label class="has_error" v-show="errors.has('last_name')">
                {{ $locale == 'es' ? 'Este campo es requerido' : 'This field is required'}}
            </label>
        </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="!isNotRegistred">
        <p class="f-weight-7 text-dark mb-2">{{ $locale == 'es' ? 'Correo electrónico' : 'Email'}}</p>
        <input
            name="email"
            class="input-type-2 mb-2 w-100"
            v-model="user.email"
            :data-vv-as="'email'"
            v-validate="'required|email'"
            :placeholder="emailPlaceholder"/>
        <label class="has_error" v-show="errors.has('email')">
            {{ $locale == 'es' ? 
                (user.email ? 'Inserta un correo valido' : 'Este campo es requerido') : 
                (user.email ? 'Insert a valid email' : 'This field is required')
            }}
        </label>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-else>
        <label class="input-type-2 mb-2 w-100">{{user.email}}</label>
    </div>
  </div>

  <div slot="footer">
    <a v-if="buttonText" 
        class="btn btn-primary d-flex flex-nowrap align-items-center justify-content-center"
        @click.prevent="manageAction()"
        :disabled="isLoading">
        
        <div class="pe-3" v-html="buttonText"></div> 
        <div v-if="isLoading" class="text-center">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" alt="arrow" class="arrow-icon" v-else>
    </a>
  </div>
</Modal>
