<template>
    <Modal v-if="showModal" @close="hide()">
      <h5 slot="header" class="modal-title pl-3"></h5>

      <div class="pl-3 pr-3" slot="body">
        <h5 class="f-weight-7 text-dark border-bottom pb-2">{{ $locale == 'es' ? 'Inicia sesión' : 'Log in'}}</h5>
        <p class="description mb-2 text-secondary f-weight-7"> {{ titleText }} </p>

        <div class="row" v-if="isNotRegistred">
            <p class="f-weight-7 text-dark mb-2">{{ $locale == 'es' ? 'Correo electrónico' : 'E-mail'}}</p>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <input
                    name="given_name"
                    class="input-type-2 mb-2 w-100"
                    :data-vv-as="'given_name'"
                    v-validate="'required'"
                    v-model="user.given_name"
                    :placeholder="givenNamePlaceholder"/>
                <label class="has_error" v-show="errors.has('given_name')">
                    {{ $locale == 'es' ? 'Este campo es requerido' : 'This field is required'}}
                </label>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <input
                    name="last_name"
                    class="input-type-2 mb-2 w-100"
                    :data-vv-as="'last_name'"
                    v-validate="'required'"
                    v-model="user.last_name"
                    :placeholder="lastNamePlaceholder"/>
                <label class="has_error" v-show="errors.has('last_name')">
                    {{ $locale == 'es' ? 'Este campo es requerido' : 'This field is required'}}
                </label>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="!isNotRegistred">
            <p class="f-weight-7 text-dark mb-2">{{ $locale == 'es' ? 'Correo electrónico' : 'Email'}}</p>
            <input
                name="email"
                class="input-type-2 mb-2 w-100"
                v-model="user.email"
                :data-vv-as="'email'"
                v-validate="'required|email'"
                :placeholder="emailPlaceholder"/>
            <label class="has_error" v-show="errors.has('email')">
                {{ $locale == 'es' ? 
                    (user.email ? 'Inserta un correo valido' : 'Este campo es requerido') : 
                    (user.email ? 'Insert a valid email' : 'This field is required')
                }}
            </label>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-else>
            <label class="input-type-2 mb-2 w-100">{{user.email}}</label>
        </div>
      </div>

      <div slot="footer">
        <a v-if="buttonText" 
            class="btn btn-primary d-flex flex-nowrap align-items-center justify-content-center"
            @click.prevent="manageAction()"
            :disabled="isLoading">
            
            <div class="pe-3" v-html="buttonText"></div> 
            <div v-if="isLoading" class="text-center">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" alt="arrow" class="arrow-icon" v-else>
        </a>
      </div>
    </Modal>
</template>
<script nonce>
import Modal from '@/components/settings/index/components/Modal.vue';
export default {
    $_veeValidate: {
      validator: 'new' // give me my own validator scope.
    },
    data: () => ({
        user: {
            given_name: null,
            last_name: null,
            email: null,
            form_slug: 'register-form'
        },
        isNotRegistred: false,
        showModal: false,
        isLoading: false,
    }),
    components:{ Modal },
    computed: {
        buttonText() {
            return this.isNotRegistred ? (this.$locale == 'es' ? 'Registrarse' : 'Register') : (this.$locale == 'es' ? 'Enviar' : 'Submit' );
        },
        titleText() {
            return this.$locale == 'es' ? 'Ingresa tu correo electrónico' : 'Enter your email'
        },
        modalSuccessText() {
            return this.$locale == 'es' ? 'Se envió el magic link' : 'Magic link sent'
        },
        modalErrorText() {
            return this.$locale == 'es' ? 'Han ocurrido problemas en el servidor, intenta más tarde' : 'There have been problems on the server, please try again later'
        },
        givenNamePlaceholder() {
            return this.$locale == 'es' ? 'Nombre(s)' : 'Name(s)'
        },
        lastNamePlaceholder() {
            return this.$locale == 'es' ? 'Apellidos' : 'Last name'
        },
        emailPlaceholder() {
            return this.$locale == 'es' ? 'Tu e-mail' : 'Your email'
        }
    },
    methods: {
        show() {
            this.showModal = true;  
        },
        manageAction() {
            if (this.isNotRegistred) {
                this.createContact();
            } else {
                this.submit()
            }
        },
        submit(){
            this.$validator.resume();
            this.$validator.validate().then(value => {
                if (value) this.verifyEmail();
            });
        },
        verifyEmail() {
            this.isLoading = true;

            this.$axios.post('/api/v1/login/check-email', {
                email: this.user.email
            }).then((r) => {
                if (r.data.status == 'email_exists') {
                    this.sendMagicLink();
                } else {
                    this.isNotRegistred = true;
                }
            }).finally(() => {
                this.isLoading = false;
            })
        },
        sendMagicLink(){
            this.$axios.post(`/api/v1/login/send-magic-link?locale=${this.$locale}`, {
                data: {
                    email: this.user.email
                }
            }).then((r)=>{   
                this.$swal.fire({
                    icon: 'success',
                    title: this.modalSuccessText,
                    showConfirmButton: true
                });
            }).catch(e => {
                this.$swal.fire({
                    icon: 'error',
                    title: this.modalErrorText,
                    showConfirmButton: true
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        createContact(){
            this.$validator.resume();
            this.$validator.validate().then(value => {
                if (value) {
                    this.isLoading = true;
                    this.$axios.post('/api/v1/login/create-contact',{
                        data: this.user
                    }).then((r)=>{   
                        this.sendMagicLink();
                    }).catch(e => {
                        this.$swal.fire({
                            icon: 'error',
                            title: this.modalErrorText,
                            showConfirmButton: true
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        hide(){
            this.showModal = false;
            this.user = {
                given_name: null,
                last_name: null,
                email: null,
                form_slug: 'register-form'
            }
            this.isNotRegistred = false
        }
    }
}
</script>
<style lang="scss" nonce>
    .modal-footer{
        border-top: 0px !important;
    }
    .modal-header{
        border-bottom: 0px;
        color: #009DF4 !important;
    }
    .modal-header .close{
        margin-right: 0px;
    }
    .send_button{
        width: 200px;
        margin-right: 15px;
    }
    .description{
        font-size: 15px;
    }
    .modal-content{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 3.5rem;
    }
    .modal-header h5{
        font-size: 20px;
    }
</style>